var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1000px"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("uploadfile.upload_excel"))),_c('v-spacer'),_c('v-icon',{attrs:{"color":_vm.color.theme,"dense":"","dark":"","size":"35"}},[_vm._v("mdi-file-upload")])],1),_c('v-divider'),_c('v-card-text',[_c('br'),_c('v-layout',[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"File input","multiple":"","placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('v-layout',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"blue lighten-1","dark":""},on:{"click":function($event){return _vm.fn_load_employee()}}},[_vm._v(_vm._s(_vm.$t("uploadfile.list_of_employees")))]),_c('v-btn',{attrs:{"color":"teal lighten-1","dark":""},on:{"click":function($event){return _vm.download_form()}}},[_vm._v(_vm._s(_vm.$t("uploadfile.download_form")))])],1),(_vm.showdata === true)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headerstaff,"items":_vm.dataEmployee,"page":_vm.pageE,"items-per-page":_vm.sizeE,"hide-default-footer":true},on:{"update:page":function($event){_vm.pageE=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.user_name",fn:function(ref){
var header = ref.header;
return [_c('span',{style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.oneid_user_id",fn:function(ref){
var header = ref.header;
return [_c('span',{style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.business_email",fn:function(ref){
var header = ref.header;
return [_c('span',{style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.mobile",fn:function(ref){
var header = ref.header;
return [_c('span',{style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"item",fn:function(props){return [_c('tr',[(_vm.$t('default') === 'en')?_c('td',{attrs:{"width":"30%"}},[_vm._v(_vm._s(props.item.user_name_en))]):_c('td',{attrs:{"width":"30%"}},[_vm._v(_vm._s(props.item.user_name_th))]),_c('td',{attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.oneid_user_id))]),_c('td',{attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.business_email))]),_c('td',{attrs:{"width":"15%"}},[_vm._v(_vm._s(props.item.mobile))])])]}}],null,true)}):_vm._e(),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[(_vm.lengthdataEmployee > 0)?_c('v-flex',{attrs:{"xs11":"","lg11":""}},[_c('v-pagination',{attrs:{"total-visible":10,"length":_vm.pageCountEM|| 0,"color":_vm.color.theme},model:{value:(_vm.pageE),callback:function ($$v) {_vm.pageE=$$v},expression:"pageE"}})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":_vm.color.darkTheme,"color":_vm.color.theme,"loading":_vm.dis_upload},on:{"click":function($event){return _vm.fn_upload_excel()}}},[_vm._v(_vm._s(_vm.$t("uploadfile.buttonupload")))]),_c('v-btn',{staticClass:"white--text",attrs:{"disable":_vm.dis_upload,"color":"red","disabled":_vm.createprogress},on:{"click":function($event){_vm.$emit('close'), _vm.cleardata()}}},[_vm._v(_vm._s(_vm.$t("uploadfile.buttonclose")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }